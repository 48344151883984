:root {
  --base-color: rgba(255, 255, 255, 0.85);
  --radius: 15px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#root {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font: bold 1rem arial, sans-serif;
  background-color: rgb(164, 235, 255);
  border-radius: 15px;
  margin: auto;
  margin-bottom: 1rem;
  height: 40px;
  width: 6rem;
}

.btn:hover,
option:hover {
  cursor: pointer;
}

.outer-box,
header,
nav {
  width: 95vw;
}

.outer-box {
  padding: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;

  overflow-x: hidden;
  border-radius: var(--radius);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1080px) {
  .outer-box,
  header,
  nav {
    max-width: 1080px;
  }

  .btn {
    margin: 0;
  }
}

nav, header {
  display: none;
}