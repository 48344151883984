:root {
  --xpos: 50%;
  --ypos: 50%;
  --mxpos: calc(var(--xpos) * 1.125);
  --txpos: calc(var(--mxpos) * 1.125);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  background-image: url("background.jpg");
  background-repeat: no-repeat;
  background-position: var(--xpos) var(--ypos);
  background-attachment: fixed;
  background-size: cover;
}

#root {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon {
  transform: rotate(180deg);
}

@media screen and (max-width: 780px) {

  body {
    background-position: var(--txpos);
  }
}

@media screen and (max-wdith: 480px) {
  
  body {
    background-position: var(--mxpos);
  }
}